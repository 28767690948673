import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import store from './store';
import routes from './routes';
import PrivateRoute from './components/PrivateRoute';
import GuestRoute from './components/GuestRoute';
import Maintenance from './pages/Maintenance';
import useKairos3Tracker from './hooks/kairos3-tracker';
import './styles/App.less';

export default function App() {
  return (
    <ConfigProvider locale={jaJP}>
      <Provider store={store}>
        <BrowserRouter>
          <Pages />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

function Pages() {
  useKairos3Tracker();

  return (
    <Routes>
      {routes.map(config => {
        return config.private ? (
          <Route
            key={config.path}
            path={config.path}
            element={<PrivateRoute {...config} />}
          />
        ) : (
          <Route
            key={config.path}
            path={config.path}
            element={<GuestRoute {...config} />}
          />
        );
      })}
      <Route key="maintenance" path="maintenance" element={<Maintenance />} />
    </Routes>
  );
}

export function MaintenanceMode() {
  return (
    <ConfigProvider locale={jaJP}>
      <Provider store={store}>
        <Maintenance />
      </Provider>
    </ConfigProvider>
  );
}
