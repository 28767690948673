import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useKairos3Tracker() {
  const location = useLocation();

  useEffect(() => {
    if (!window.Kairos3Tracker) return () => {};

    const existingScript = document.getElementById('kairos3');
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = 'kairos3';
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.src = '//c.k3r.jp';
    document.body.appendChild(script);
  }, [location.pathname]);
}
